import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import MuiAlert from "@mui/material/Alert";
import MuiSnackbar from "@mui/material/Snackbar";
import { SUCCESS, WARNING } from "@/constants/constants";

const Alert = ({
  message,
  setShowAlert,
  showAlert,
  snackbar = true,
  type = SUCCESS,
}) => {
  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <>
      {snackbar ? (
        <MuiSnackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          autoHideDuration={type !== WARNING ? 6000 : 10000}
          open={showAlert}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            iconMapping={{
              error: <ErrorIcon />,
              info: <InfoIcon />,
              success: <CheckCircleIcon />,
              warning: <WarningIcon />,
            }}
            severity={type}
          >
            {message}
          </MuiAlert>
        </MuiSnackbar>
      ) : (
        <MuiAlert
          iconMapping={{
            error: <ErrorIcon />,
            info: <InfoIcon />,
            success: <CheckCircleIcon />,
            warning: <WarningIcon />,
          }}
          severity={type}
        >
          {message}
        </MuiAlert>
      )}
    </>
  );
};

export default Alert;
