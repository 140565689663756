//AUTH
export const accessTokenKey = "accesstoken";
export const idTokenKey = "idtoken";
export const idTokenExpiryKey = "idTokenExpiry";
export const idTokenRealmKey = "idTokenRealm";
export const userNameKey = "name";
export const marketCodeKey = "marketCode";
export const userQnumberKey = "userQNumber";
export const masterRetailerNumberKey = "masterRetailerNumber";
export const dealerNameKey = "dealerName";
export const businessPartnerIdKey = "businessPartnerId";
export const canMarketCreateBPKey = "canMarketCreateBP";
export const canMarketEditBPKey = "canMarketEditBP";
export const canMarketCreateContractKey = "canMarketCreateContract";
export const canMarketEditContractKey = "canMarketEditContract";
export const canMarketCreateContactKey = "canMarketCreateContact";
export const canMarketEditContactKey = "canMarketEditContact";
export const canMarketCreateBPViaDunsKey = "canMarketCreateBPViaDuns";
export const defaultDealerId = "defaultDealerId";
export const userKey = "user";
export const languageCode = "languageCode";

//BUSINESS PARTNER
// @TODO - this should be changed to values companyHierarchy->values
// and checked against that value in all instances
export const LEVEL2 = "Level 2";
export const LEVEL3 = "Level 3";

// ALERT STATUSES
export const ERROR = "error";
export const SUCCESS = "success";
export const WARNING = "warning";

// MODES
export const CREATE = "CREATE";
export const EDIT = "EDIT";
export const VIEW = "VIEW";

// YES OR NO
export const NO = "No";
export const YES = "Yes";

// Autocomplete input reasons
export const CLEAR = "clear";
export const RESET = "reset";
export const INPUT = "input";

// ENTITIES
export const BMW_ACCOUNT = "account";
export const BMW_CONTRACT = "bmw_contract";
export const BMW_ORDER = "bmw_order";

// CONTRACT STATUSES
// Todo: instead of status comparing via plain string we may consider using the number thats associated with status like 1700456
export const CONTRACT_APPROVED = "Contract Approved";
export const CONTRACT_AUTHORISED = "Contract Authorised";
export const CONTRACT_PREPARATION = "Contract Preparation";
export const CONTRACT_PRESENTED = "Contract Presented";
export const CONTRACT_REJECTED = "Contract Rejected";
export const ACTIVE = "Active";
export const NOT_TAKEN_UP = "Not Taken Up";
export const CONTRACT_SENTFORSALESAPPROVAL = "Contract Sent for Sales Approval";
export const CONTRACT_SENTFORFINANCIALAPPROVAL =
    "Contract Sent for Financial Approval";
export const CONTRACT_SALESMODEL = "Indirect";
export const CONTRACT_TERMINATED = "Contract Terminated"
export const CONTRACT_EXPIRED = "Contract Expired"

// CONTRACT STATUSES VALUES
export const NOT_TAKEN_UP_VALUE = 174640008;
export const CONTRACT_AUTHORISED_VALUE = 174640011;
export const CONTRACT_TERMINATED_VALUE = 174640016;
export const CONTRACT_EXPIRed_VALUE = 174640019; 
export const ACTIVE_VALUE = 174640015;

// VERIFICATION STATUES
export const UPLOAD = "Upload";
export const RE_UPLOAD = "Re-upload";

// PAGINATION
export const PAGE_SIZE = 25;
export const PANEL_PAGE_SIZE = 5;

// NKAM STATUSES
export const ACCOUNT_LOST = "Account Lost";
export const CUSTOMER = "Customer";
export const DOWNGRADED = "Downgraded";
export const LAPSED = "Lapsed";
export const LEAD = "Lead";
export const PROSPECT = "Prospect";
export const SUSPECT = "In Evaluation";
export const OTHER = "Other";

// SELECT VALUES
export const CUSTOMER_VALUE = 174640002;
export const DOWNGRADED_VALUE = 174640005;
export const LEAD_VALUE = 174640001;
export const LEVEL2_VALUE = 174640000;
export const LEVEL3_VALUE = 174640001;
export const PROSPECT_VALUE = 174640000;
export const LAPSED_VALUE = 174640003;
export const ACCOUNT_LOST_VALUE = 174640004;
export const SUSPECT_VALUE = 174640009;

// BACKDROP TYPES
export const LOADING = "Loading";
export const SAVING = "Saving";

// GUID
export const EMPTY_GUID = "00000000-0000-0000-0000-000000000000";

// Default values
export const DEFAULT_LANGUAGE = 174640000;
export const ENGLISH_LANGUAGE = 174641033;

// VERIFICATION STATUSES
export const VERIFICATION_STATUS_REUPLOAD = 174640002;
export const VERIFICATION_STATUS_DECLINED = 174640004;

export const GreyMarketCheckResult_SellingAllowed = 174640002;
export const CoinCheckResult_SellingAllowed = 174640002;

//Account type
export const SPECIAL_CUSTOMER = "Special Customer";
export const SUB_TYPE_FIELD_NAME = "Sub type";
export const SUB_TYPE_SCHEME_NAME = "bmw_AccountSubTypeId";
// Status dependant Required fields
export const ACCOUNT_LOST_REQUIRED =
    "NKAM.BusinessPartner.RequiredFields.AccountLost";
export const CUSTOMER_REQUIRED = "NKAM.BusinessPartner.RequiredFields.Customer";
export const DOWNGRADED_REQUIRED =
    "NKAM.BusinessPartner.RequiredFields.Downgraded";
export const LEAD_REQUIRED = "NKAM.BusinessPartner.RequiredFields.Lead";
export const SUSPECT_REQUIRED =
    "NKAM.BusinessPartner.RequiredFields.InEvaluation";
export const PROSPECT_REQUIRED = "NKAM.BusinessPartner.RequiredFields.Prospect";
export const LAPSED_REQUIRED = "NKAM.BusinessPartner.RequiredFields.Lapsed";
export const PRIMARY_LANGUAGE_CONFIG = "NKAM.Default.PrimaryLanguage";
export const CONTACT_REQUIRED_FIELDS = "NKAM.Contact.RequiredFields";
export const ALLOW_OWNING_KAM_EDIT = "NKAM.DealerPortal.OwningKAMVisibility";
export const BP_ComplianceCheck_ContractCheck =
    "NKAM.BusinessPartner.ComplianceChecks.ContractCheck";
export const Contract_GreyMarketCoin_ComplianceMessage =
    "NKAM.Contract.GreyMarketCoin.ComplianceMessage";
export const BusinessPartner_ComplianceCheck_LeadCheckConfig =
    "NKAM.BusinessPartner.ComplianceChecks.LeadCheck";
export const BusinessPartner_ComplianceCheck_ErrorMessageConfig =
    "NKAM.BusinessPartner.ComplianceChecks.LeadExceptionMessage";
export const Contact_ComplianceChecks_ContractCheck =
    "NKAM.Contact.ComplianceChecks.ContractCheck";
export const Contract_Coin_ComplianceMessage =
    "NKAM.Contract.Coin.ComplianceMessage";
export const NATIONAL_ID1_LABEL = "NKAM.BusinessPartner.Label.NSCCompanyID";
export const NATIONAL_ID2_LABEL = "NKAM.BusinessPartner.Label.NSCCompanyID2";
export const NATIONAL_ID3_LABEL = "NKAM.BusinessPartner.Label.NSCCompanyID3";
export const Contact_ComplianceCheck_ContractRoles =
    "NKAM.Contact.ComplianceChecks.ContractRoles";
export const Contact_ComplianceCheck_ContactRoles_ErrorMessage =
    "NKAM.Contact.ComplianceChecks.ContactRoleException";
export const Contact_CoinCheck_SellingNotAllowed_Message =
    "NKAM.Contact.CoinCheck.SellingNotAllowed";
export const Contact_ComplianceChecks_LeadCheck =
    "NKAM.Contact.ComplianceChecks.LeadCheck";
export const BusinessPartner_ProspectStatus_Config =
    "NKAM.BusinessPartner.Status.Prospect";
export const BusinessPartner_InEvaluationStatus_Config =
    "Portal.BusinessPartner.Status.InEvaluation";
export const BusinessPartner_Lead_Config = "NKAM.BusinessPartner.Status.Lead";
export const BusinessPartner_CustomerStatus_Config =
    "NKAM.BusinessPartner.Status.Customer";
export const BusinessPartner_DowngradedStatus_Config =
    "NKAM.BusinessPartner.Status.Downgraded";
export const BusinessPartner_LapsedStatus_Config =
    "NKAM.BusinessPartner.Status.Lapsed";
export const BusinessPartner_AccountLostStatus_Config =
    "NKAM.BusinessPartner.Status.AccountLost";
export const BusinessPartner_OtherStatus_Config =
    "NKAM.BusinessPartner.Status.Other";
export const BusinessPartner_VatNotApplicable_Config = "NKAM.BusinessPartner.VATNotApplicable.Enabled";
export const BusinessPartner_VatNotApplicableMessage_Config = "NKAM.BusinessPartner.VATNotApplicable.Message";
export const BusinessPartner_CloneVAT_Config = "NKAM.BusinessPartner.CloneVAT";
export const NKAM_ESO_Search_Dialog = "Portal.Eso.Search.Fields";
export const NKAM_ESOSearch_CountryList = "NKAM.ESOSearch.CountryList";
export const NKAM_Globus_Status_Lock = "NKAM.Globus.Status.Lock";
export const NKAM_ESO_Search = "NKAM.ESO.Search";
export const Portal_BusinessPartner_PrimaryContact_Error = "Portal.BusinessPartner.PrimaryContact.Error";
export const NKAM_BusinessPartner_Missing_GLOBUSId_Message = "NKAM.BusinessPartner.Missing.GLOBUSId.Message";
export const NKAM_All_GenericStartEndDateError_Message = "NKAM.All.GenericStartEndDateError.Message"
export const NKAM_AllGeneric_StartDateSetBeforePresentDay = "NKAM.AllGeneric.StartDateSetBeforePresentDay" 
export const NKAM_Contract_AllowedStatusMovement = "NKAM.Contract.AllowedStatusMovement";
export const NKAM_BusinessPartner_Missing_GCID_Message = "NKAM.BusinessPartner.Missing.GCID.Message"
//UI CONSTAANTS
export const DRAWER_WIDTH_OPEN = 230;
export const DRAWER_WIDTH_CLOSED = 70;
//Legal entity type BP Requirement Override
export const FIELD_REQUIRED = 174640000;
export const FIELD_NOT_REQUIRED = 174640001;
export const USE_MARKET_CONFIG = 174640002;

// Static error messages in case configuration fails
export const GenericStartEndDateErrorMessage = "genericDateMessages.startEndDateErrorMessage"
export const StartDateSetBeforePresentDay = "genericDateMessages.startDateBeforePresentDay"

//StreetPOBoxCheck
export const NKAM_BusinessPartner_StreetPOBoxCheck ="NKAM.BusinessPartner.StreetPOBoxCheck";
export const NKAM_Contact_StreetPOBoxCheck="NKAM.Contact.StreetPOBoxCheck";

export const NKAM_Default_InvoiceLanguage="NKAM.Default.InvoiceLanguage";