import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { styled } from "@mui/material/styles";
import AttachFile from "@mui/icons-material/AttachFile";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Alert from "@/components/Form/Alert";
import AppError from "@/AppError";
import ConditionalTooltip from "@/components/ConditionalTooltip";
import Loader from "@/components/Loader";
import Paging from "@/components/Paging";
import { WARNING } from "@/constants/constants";
import dataURLtoFile from "@/utils/dataURLtoFile";
import { handleApiError } from "@/utils/handleApiError";
import apiBPData from "@/services/apiBusinessPartners";
import { useDebounce } from "@/hooks/useDebounce";
import { useQueries } from "@tanstack/react-query";
import { useTimelineListQuery } from "@/services/queries";
import { useTranslation } from "react-i18next";
import {
  StyledContainer,
  StyledContents,
  StyledHeader,
  StyledHeading,
  StyledNoResults,
  StyledPagination,
  StyledTbody,
  StyledTr,
} from "./Styles/PagesComponentsCommon";
import TableHeaders from "./TableHeaders";

const Timeline = ({
  id,
  func,
  orderRejectedComment,
  setOpen,
  setUpdateTimeline,
  updateTimeline,
  createFunctionality = false,
  searchFunctionality = false,
  disableCreateNote = false,
  title,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [timelineFiles, setTimelineFiles] = useState([]);
  const [receivedResponse, setReceivedResponse] = useState(false);
  const [apiError, setApiError] = useState({});

  const headings = [
    { heading: t("timeline.actionsBox.tableHeadings.title"), isAction: false },
    { heading: t("timeline.actionsBox.tableHeadings.type"), isAction: false },
    { heading: t("timeline.actionsBox.tableHeadings.attachement"), isAction: false },
    { heading: t("timeline.actionsBox.tableHeadings.modified"), isAction: false },
  ];
  const debouncedSearch = useDebounce([searchValue]);
  const timelineListQuery = useTimelineListQuery(
    id,
    searchValue,
    page,
    func,
    debouncedSearch,
  );
  if (title === undefined) {
    title = t("timeline.title");
  }
  const getFileDetailsQueries = useQueries({
    queries: results.map((item) => {
      return {
        queryKey: [item.id, results],
        queryFn: async ({ signal }) => {
          let res = await apiBPData.getTimelineNoteFileDetails(item.id, signal);

          return {
            id: item.id,
            res,
          };
        },
        enabled: Boolean(item?.fileName),
      };
    }),
  });

  const isLoading =
    timelineListQuery.isFetching ||
    getFileDetailsQueries.some((query) => query.isFetching);

  useEffect(() => {
    if (timelineListQuery.isError) {
      setApiError(handleApiError(timelineListQuery.error));
    }
    if (timelineListQuery.isSuccess) {
      setResults(timelineListQuery.data.data.result);
      setTotalPages(timelineListQuery.data.data.totalPages);
      setReceivedResponse(true);
      setUpdateTimeline(false);
    }
  }, [timelineListQuery.dataUpdatedAt, timelineListQuery.errorUpdatedAt]);

  useEffect(() => {
    if (getFileDetailsQueries.some((query) => query.isError)) {
      setApiError(
        handleApiError(
          getFileDetailsQueries.find((query) => query.isError)?.error,
        ),
      );
    }
    if (getFileDetailsQueries.some((query) => query.isSuccess)) {
      getFileDetailsQueries.map((query) => {
        if (
          query?.data?.res?.data?.result &&
          query?.data?.res?.data?.result?.fileName &&
          query?.data?.res?.data?.result?.mimeType &&
          query?.data?.res?.data?.result?.documentBody
        ) {
          const { documentBody, fileName, mimeType } =
            query.data.res.data.result;
          const file = createDownloadUrl(documentBody, fileName, mimeType);
          let updatedValue = {};
          updatedValue = { [query.data.id]: { file } };
          setTimelineFiles((prevState) => ({
            ...prevState,
            ...updatedValue,
          }));
        }
      });
    }
  }, [getFileDetailsQueries]);

  useEffect(() => {
    if (updateTimeline) {
      timelineListQuery.refetch();
    }
  }, [updateTimeline]);

  const createDownloadUrl = (dataUrl, filename, mimeType) => {
    const file = dataURLtoFile(dataUrl, filename, mimeType);
    return URL.createObjectURL(file);
  };

  const updateSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    return () => {
      // queryClient.invalidateQueries();
    };
  }, []);

  return (
    <>
      {receivedResponse && (
        <StyledContainer id="timeline">
          <Box>
            <StyledHeader>
              <StyledContents>
                <StyledHeading component="h2" variant="h4">
                  {title}
                </StyledHeading>
              </StyledContents>

              {searchFunctionality && (
                <TimelineSearchField
                  label={t("timeline.searchField")}
                  margin="none"
                  onChange={updateSearch}
                  size="small"
                  type="search"
                  value={searchValue}
                />
              )}
            </StyledHeader>
          </Box>

          {(searchValue?.length >= 3 ||
            createFunctionality ||
            orderRejectedComment) && (
            <TimelineActionsBox>
              {orderRejectedComment && (
                <Alert
                  message={t("timeline.actionsBox.rejectedComment", {
                    rejectedComment: orderRejectedComment,
                  })}
                  snackbar={false}
                  type={WARNING}
                />
              )}

              {createFunctionality && (
                <TimelineCreateButton
                  onClick={() => setOpen(true)}
                  type="button"
                  disabled={disableCreateNote}
                >
                  {t("timeline.actionsBox.createNote")}
                </TimelineCreateButton>
              )}

              {searchValue?.length > 0 && (
                <Typography>
                  {t("timeline.actionsBox.searchResultsFor", {
                    searchVal: searchValue,
                  })}
                  <TimelineClearButton
                    component="button"
                    variant="body2"
                    onClick={() => setSearchValue("")}
                  >
                    {t("timeline.actionsBox.clearSearch")}
                  </TimelineClearButton>
                </Typography>
              )}
            </TimelineActionsBox>
          )}

          <TableHeaders headings={headings}>
            {results.length > 0 ? (
              <StyledTbody>
                {isLoading && <Loader />}

                {results.map(
                  (
                    {
                      attachmentOrComments,
                      date,
                      fileName,
                      modifiedBy,
                      title,
                      type,
                      id,
                    },
                    index,
                  ) => (
                    <StyledTr key={index} className="timeLine-tableTr">
                      <TimelineTableTD>
                        <ConditionalTooltip defaultValue="" title={title} />
                      </TimelineTableTD>
                      <TimelineTableTD>
                        <ConditionalTooltip defaultValue="" title={type} />
                      </TimelineTableTD>

                      <TimelineTableTD>
                        {fileName && timelineFiles[id] && (
                          <TimelineFile title={fileName}>
                            <Link
                              download={fileName}
                              href={timelineFiles[id]?.file}
                              disabled
                            >
                              <TimelineIcon fontSize="inherit" />

                              {fileName}
                            </Link>
                          </TimelineFile>
                        )}

                        <TimelineComment
                          component="p"
                          defaultValue=""
                          title={attachmentOrComments}
                          variant="caption"
                        />
                      </TimelineTableTD>

                      <TimelineTableTD>
                        <ConditionalTooltip
                          defaultValue=""
                          title={modifiedBy?.name}
                        />

                        <TimelineDate variant="caption">
                          <Moment format="DD/MM/YYYY HH:mm">{date}</Moment>
                        </TimelineDate>
                      </TimelineTableTD>
                    </StyledTr>
                  ),
                )}
              </StyledTbody>
            ) : (
              <StyledNoResults>
                {t("timeline.actionsBox.noResults")}
              </StyledNoResults>
            )}
          </TableHeaders>

          <StyledPagination>
            {totalPages > 1 && (
              <Paging count={totalPages} page={page} setPage={setPage} />
            )}
          </StyledPagination>
        </StyledContainer>
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};

const TimelineSearchField = styled(TextField)(() => ({
  flexBasis: "38.961%",
}));
const TimelineActionsBox = styled(Box)(({ theme }) => ({
  borderTop: "1px solid #dce0e4",
  padding: theme.spacing(2, 3),
  "& > *:not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
}));
const TimelineCreateButton = styled(Button)(() => ({
  display: "flex",
  marginLeft: "auto",
  width: 160,
}));
const TimelineClearButton = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
}));

const TimelineTableTD = styled(Box)(({ theme }) => ({
  borderBottom: "1px solid #8a8c8f",
  display: "table-cell",
  height: 60,
  padding: theme.spacing(1),
  verticalAlign: "top",
}));
const TimelineFile = styled(ConditionalTooltip)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
}));
const TimelineIcon = styled(AttachFile)(({ theme }) => ({
  marginRight: theme.spacing(0.25),
}));
const TimelineComment = styled(ConditionalTooltip)(({ theme }) => ({
  color: theme.palette.text.hint,
}));
const TimelineDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.hint,
  display: "block",
  marginTop: theme.spacing(0.5),
}));

export default Timeline;
